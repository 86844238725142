.send-select-vehicle-root{
    font-style: normal;
    font-weight: bold;
}
.send-select-vehicle-page{
    display: flex;
    align-items: center;
    height: 26px;
    min-width: 252px;
    margin-top: 18px;
}
.send-select-vehicle-label-frame{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.send-select-vehicle-label{
    letter-spacing: 0;
    min-width: 252px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #021E20;
}
.send-select-vehicle-root .button-frame{
    margin-top: 24px;
}
@media (max-width:1023px) {
    .send-select-vehicle-page > .back {
        display: none;
    }
}
@media (min-width: 1024px) {
    .send-select-vehicle-root .button-frame{
        margin-top: 36px;
    }
    .send-select-vehicle-page{
        height: 70px;
        width: 90%;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
    }
    .send-select-vehicle-label{
        margin-right: 142px;
        min-width: 447px;
        font-size: 32px;
        line-height: 46px;
    }
}
@media (min-width: 1400px) {
    .send-select-vehicle-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}