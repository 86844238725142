.settings-password-input-root{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}
.settings-password-input-page{
    display: flex;
    align-items: center;
    min-height: 26px;
    min-width: 126px;
    margin-top: 18px;
}
.settings-password-input-label-frame{
    width: 100%;
}
.settings-password-input-label{
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #021E20;
    min-width: 126px;
}
.settings-password-input-root .password-frame{
    margin-top: 30px;
}
.settings-password-input-root .password-frame + .password-frame{
    margin-top: 24px;
}
.settings-password-input-root .password-frame > .password-label{
    min-width: 112px;
}
.settings-password-input-root .button-frame{
    margin-top: 40px;
}
@media (max-width: 1023px) {
    .settings-password-input-page > .back {
        display: none;
    }
}
@media (min-width: 1024px) {
    .settings-password-input-page{
        width: 90%;
        min-height: 70px;
        min-width: 922px;
        max-width: 1074px;
        margin-top: 25px;
    }
    .settings-password-input-label{
        font-size: 32px;
        line-height: 46px;
        min-width: 160px;
        margin-right: 142px;
    }
    .settings-password-input-root .password-frame{
        margin-top: 30px;
    }
    .settings-password-input-root .password-frame + .password-frame{
        margin-top: 40px;
    }
    .settings-password-input-root .password-frame > .password-label{
        min-width: 176px;
    }
    .settings-password-input-root .button-frame{
        margin-top: 40px;
    }
}
@media (min-width: 1400px) {
    .settings-password-input-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}