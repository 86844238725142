.return-select-vehicle-page{
    align-items: center;
    display: flex;   
    height: 26px;
    margin-top: 18px;
    min-width: 252px;
   }
.return-select-vehicle-label-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.return-select-vehicle-label{
       letter-spacing: 0;
       min-width: 252px;
       font-style: normal;
       font-weight: bold;
       font-size: 18px;
       line-height: 26px;
       text-align: center;
       color: #021E20;
   }
.return-select-vehicle-root > .button-frame{
    margin-top: 24px;
}   
@media (max-width:1023px) {
    .return-select-vehicle-page > .back {
            display: none;
        }
    }
@media (min-width: 1024px) {
    .return-select-vehicle-page{
        height: 70px;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
        width: 90%;
       }
    .return-select-vehicle-label{
           margin-right: 142px;
           min-width: 447px;
           font-size: 32px;
           line-height: 46px;
    }
    .return-select-vehicle-root > .button-frame{
        margin-top: 36px;
        height: 109px;
    }
}
@media (min-width: 1400px) {
    .return-select-vehicle-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}