.component-target-point{
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 76px;
    position: relative;
    min-width: 102px;
    width: 90%;
}
.component-target-point-label-frame{
    background-color: #00ADBB;
    align-items: center;
    border-radius: 50px;
    display: flex;
    height: 31px;
    min-height: 31px;
    justify-content: center;
    min-width: 60px;
}
.component-target-point-label{
    letter-spacing: 0;
    min-width: 36px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 2px;
    text-align: center;
    color: #FFFFFF;
}
.component-target-point-address{
    margin-top: 5px;
    min-width: 102px;
    letter-spacing: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    word-wrap: break-word;
    line-break: anywhere;
    color: #00ADBB;
    text-align: center;
}
.enable-target-point > .component-target-point-address{
    color: #00ADBB;
}
.disable-target-point > .component-target-point-address{
    color: #D9D8D6;
}
@media (min-width: 1024px) {
    .component-target-point{
        min-height: 110px;
        width: 180px;
    }
    .component-target-point-label-frame{
        height: 49px;
        min-width: 120;
    }
    .component-target-point-label{
        min-width: 120px;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 3px;
    }
    .component-target-point-address{
        margin-top: 12px;
        font-size: 48px;
        line-height: 39px;
        min-width: 175px;
        width: 260px;
    }
}