.question-frame{
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 172px;
    margin-top: 80px;
    height: 24px;
}
.question-icon{
    height: 24px;
    width: 24px;
}
.question-label{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #667172;
    margin-left: 8px;
}
@media (min-width: 1024px) {
    .question-frame{
        min-width: 300px;
        margin-top: 100px;
        height: 44px;
    }
    .question-icon{
        height: 44px;
        width: 44px;
    }
    .question-label{
        font-size: 24px;
        line-height: 35px;
        margin-left: 16px;
    }
}