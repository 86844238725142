.App {
  align-items: center;
  background-color: #f1f9fa;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.footer {
  width: 100%;
  text-align: center;
  color: #D9D8D6;
}
.content-root{
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}