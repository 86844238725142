.call-complete-schedule-root .simple-msges-frame{
    min-height: 338px;
}
.call-complete-schedule-root > .simple-msges-frame .button-frame{
    margin-top: 16px;
}
.call-schedule-time-frame{
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: flex-end;
    margin-top: 24px;
    min-width: 236px;
}
.call-schedule-time-label{
    letter-spacing: 0;
    margin-top: 11px;
    min-width: 96px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    margin-right: 16px;
    color: #00ADBB;
}
.call-schedule-time{
    font-family: Noto Sans;
    align-self: flex-end;
    letter-spacing: 0;
    width: 124px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    color: #00ADBB;
}
.call-complete-reservation-root .simple-msges-frame{
    min-height: 268px;
    white-space: pre-wrap;
}
.call-complete-reservation-root > .simple-msges-frame .button-frame{
    margin-top: 24px;
}
@media (min-width: 1024px) {
    .call-complete-schedule-root .simple-msges-frame{
        min-height: 440px;
    }
    .call-complete-schedule-root > .simple-msges-frame > .icon{
        margin-top: 15px;
    }
    .call-schedule-time-frame{
        height: 78px;
        min-width: 394px;
    }
    .call-schedule-time-label{
        margin-top: 12px;
        min-width: 128px;
        font-size: 32px;
        line-height: 46px;
    }
    .call-schedule-time{
        margin-right: 40px;
        width: 170px;
        font-size: 54px;
        line-height: 74px;
        margin-top: 4px;
    }
    .call-complete-schedule-root > .button-frame{
        margin-top: 24px;
    }
    .call-complete-reservation-root .simple-msges-frame{
        min-height: 390px;
    }
    .call-complete-reservation-root > .simple-msges-frame > .icon{
        margin-top: 32px;
    }
    .call-complete-reservation-root > .simple-msges-frame .button-frame{
        margin-top: 32px;
    }
    .call-complete-reservation-root > .simple-msges-button-frame{
        margin-top: 32px;
    }
}