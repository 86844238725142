.back{
    align-items: center;
    display: flex;
    height: 70px;
    min-width: 142px;
    position: relative;
    background: linear-gradient(312.47deg, #F5F5F5 15.15%, #FFFFFF 84.73%);
    box-shadow: 5px 5px 10px #E2E2E2, -5px -5px 10px #FFFFFF, inset 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}
.back-text{
    font-size: 26px;
    color: #00ADBB;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
    margin-left: 8px;
    line-height: 38px;
    min-width: 52px;
}
.iconsmallarrowleft{
    align-items: flex-start;
    display: flex;
    margin-left: 24.0px;
    margin-top: 4px;
    width: 24px;
}
.back-button-icon-frame{
    height: 24px;
    position: relative;
    width: 24px;
}
.back-icon-frame{
    height: 24px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 24px;
}
