.notification-presentation {
    height: 0;
    width: 100%;
}

.notification-presentation-vehicle {
    position: relative;
    top: 5px;
    left: 75%;
    width: 20%;

    animation-duration: 0.1s;
    animation-name: vehicle-incoming;
    animation-iteration-count: 20;
}

@keyframes vehicle-incoming {
    from {
        transform: rotate(-7deg);
    }
    to {
        transform: rotate(5deg);
    }
}

.hide {
    display: none;
}

@media (min-width: 1024px) {
    .notification-presentation-vehicle {
        left: 90%;
        width: 8%;
    }
}
