.settings-menu-frame{
    margin-top:35px ;
    margin-bottom: 10px;
}
.settings-menu-frame .button-bg-frame{
    width: 292px;
}
.settings-menu-frame .button-text{
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 263px;
}
@media (min-width: 1024px) {
    .settings-menu-frame .button-bg-frame{
        width: 400px;
    }
    .settings-menu-frame .button-text{
        width: 320px;
    }
}
@media (min-width: 1400px) {
    .settings-menu-frame{
        margin-top: 60px;
    }
    .settings-menu-frame .button-frame{
        height: 109px;
    }
}