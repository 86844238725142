.loading-vehicle-icon{
    height: 48px;
    margin-left: 1.0px;
    margin-top: 214px;
    width: 48px;
}
.loading-text{
    letter-spacing: 0;
    font-style: normal;
    margin-top: 16px;
    min-width: 287px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #00ADBB;
}
@media (min-width: 1024px) {
    .loading-vehicle-icon{
        height: 80px;
        margin-left: 2.0px;
        margin-top: 180px;
        width: 80px;
    }
    .loading-text{
        min-width: 466px;
        font-size: 26px;
        line-height: 38px;
    }
}
@media (min-width: 1400px) {
    .loading-vehicle-icon{
        margin-top: 250px;
    }
}