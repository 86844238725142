.settings-notification-root {
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}
.settings-notification-page {
    display: flex;
    align-items: center;
    min-height: 26px;
    min-width: 126px;
    margin-top: 18px;
}
.settings-notification-label-frame {
    width: 100%;
}
.settings-notification-label {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #021E20;
    min-width: 126px;
}

@media (max-width: 1023px) {
    .settings-notification-page > .back {
        display: none;
    }
}
@media (min-width: 1024px) {
    .settings-notification-page {
        height: 70px;
        width: 90%;
        margin-top: 25px;
        min-height: 70px;
        min-width: 922px;
        max-width: 1074px;
    }
    .settings-notification-label {
        margin-right: 142px;
        min-width: 160px;
        font-size: 32px;
        line-height: 46px;
    }
}
@media (min-width: 1400px) {
    .settings-notification-page {
        min-width: 1072px;
        max-width: 1072px;
    }
}
