.task{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    width: 295px;
    background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
    box-shadow:  2px 0px 8px #8CAEB0, -2px 0px 8px #BCE4E8, inset -4px -4px 8px rgba(255, 255, 255, 0.24), inset 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 22px auto 0px auto;
}
.task-label-frame{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 235px;
    margin: auto;
}
.task:active{
    background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.04), inset -8px -8px 20px rgba(255, 255, 255, 0.24), inset 8px 8px 16px rgba(0, 0, 0, 0.25);
    margin-top: 21px;
    margin-bottom: 1px;
}
.task-label{
    font-style: normal;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: white;
    line-height: 41px;
    margin-left: 4px;
}
.icon-1{
    justify-content: center;
    height: 48px;
    width: 48px;
}
.flex-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 306px;
    margin-top: 70px;
    max-width: 295px;
    width: 100%;
}
.valign-text-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (min-width: 1024px) {
    .task{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 330px;
        width: 330px;
        background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
        box-shadow: 5px 5px 10px #97B9BC, -5px -4px 10px #FFFFFF, inset 5px 5px 14px rgba(0, 0, 0, 0.1);
        border-radius: 238px;
        margin: 0px auto;
    }
    .task:active{
        background: linear-gradient(312.47deg, #40C6D9 15.15%, #00ADBB 84.73%);
        box-shadow: inset 10px 10px 32px rgba(0, 0, 0, 0.1), inset 5px 5px 20px rgba(0, 0, 0, 0.2), inset -5px -5px 15px rgba(255, 255, 255, 0.7);
        margin-top: 10px;
    }
    .task-label{
        font-style: normal;
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        color: white;
        line-height: 58px;
        margin-left: 0px;
    }
    .icon-1{
        justify-content: center;
        height: 134px;
        margin-top: 58px;
        width: 134px;
    }
    .flex-row{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: 372px;
        margin-top: 100px;
        max-width: 1098px;
        width: 100%;
    }
}
@media (min-width: 1050px) {
    .task{
        width: 342px;
        height: 342px;
    }
}
@media (min-width: 1400px) {
    .flex-row{
        margin-top: 130px;
        max-width: 1146px;
    }
    .task{
        margin: 0px 20px;
    }
    .task:active{
        margin-top: 0px;
    }
}