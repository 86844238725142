.call-select-destination-page{
    align-items: center;
    display: flex;   
    height: 52px;
    margin-top: 18px;
    min-width: 256px;
}
.call-destination-text-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-col{
    min-width: 256px;
    max-width: 98%;
}
.call-destination-address{
    letter-spacing: 0;
    min-height: 26px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}
.target-point{
    color: #00ADBB;
}
.reservation-count-text{
    color: #667071;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 10px;
    font-size: 12px;
    min-width: 145px;
    text-align: center;
}
.call-select-destination-root > .destination-list{
    margin-top: 12px;
}
@media (max-width:1023px) {
    .call-select-destination-page > .back {
            display: none;
        }
    }
@media (min-width: 1024px) {
    .call-select-destination-page{ 
        height: 70px;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
        width: 90%;
    }
    .call-destination-text-frame{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .flex-col{
        margin-left: 10px;
        max-width: 100%;
    }
    .call-destination-address{
        letter-spacing: 0;
        min-height: 46px;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 46px;
        text-align: center;
    }
    .target-point{
        color: #00ADBB;
    }
    .reservation-count-text{
        margin-top: 15px;
        font-size: 22px;
    }
    .call-select-destination-root > .destination-list{
        margin-top: 0px;
    }
}

@media (min-width: 1400px) {
    .call-select-destination-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}