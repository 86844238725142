.settings-home-confirm-page{
    display: flex;
    align-items: center;
    height: 52px;
    min-width: 162px;
    width: 98%;
    margin-top: 18px;
}
.settings-home-confirm-label-frame{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.settings-home-confirm-label{
    letter-spacing: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    min-width: 162px;
}
.settings-home-confirm-target-vehicle{
    color: #00ADBB;
}
.settings-home-confirm-home-frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 8px;
    width: 91.5%;
    min-height: 162px;
    min-width: 293px;
    max-width: 343px;
    margin-top: 24px;
}
.settings-home-confirm-home{
    display: flex;
    align-items: center;
    height: 48px;
    min-width: 158px;
    margin-right: 1px;
}
.settings-home-confirm-home-icon{
    width: 48px;
    height: 48px;
}
.settings-home-confirm-home-label{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    color: #00ADBB;
    min-width: 102px;
    max-width: 230px;
    margin-left: 8px;
    word-wrap: break-word;
    line-break: anywhere;
}
.settings-home-confirm-root .input-frame{
    height: 71px;
    margin-top: 40px;
}
.settings-home-confirm-root > .input-frame > #adminPassword::-ms-reveal{
    visibility:hidden
}
.settings-home-confirm-root .button-frame{
    margin-top: 22px;
}
@media (max-width: 1023px) {
    .settings-home-confirm-page > .back {
        display: none;
    }
}
@media (min-width: 1024px) {
    .settings-home-confirm-page{
        height: 70px;
        width: 90%;
        min-width: 922px;
        max-width: 1074px;
        margin-top: 25px;
    }
    .settings-home-confirm-label{
        font-size: 32px;
        line-height: 46px;
        min-width: 447px;
        min-height: 46px;
        margin-right: 142px;
    }
    .settings-home-confirm-home-frame{
        width: 100%;
        min-width: 560px;
        max-width: 560px;
        min-height: 208px;
        margin-top: 30px;
    }
    .settings-home-confirm-home{
        height: 80px;
        min-width: 271px;
    }
    .settings-home-confirm-home-icon{
        width: 80px;
        height: 80px;
    }
    .settings-home-confirm-home-label{
        font-size: 48px;
        line-height: 39px;
        min-width: 175px;
        max-width: 410px;
        margin-left: 12px;
    }
    .settings-home-confirm-root .input-frame{
        height: 116px;
        margin-top: 20px;
    }
    .settings-home-confirm-root .input-frame > div{
        font-size: 22px;
        line-height: 32px;
    }
    .settings-home-confirm-root .input-frame > input{
        font-size: 32px;
        line-height: 40px;
        height: 45px;
        min-width: 516px;
        padding: 12px 22px;
        margin-top: 15px;
    }
    .settings-home-confirm-root .button-frame{
        margin-top: 20px;
    }
}
@media (min-width: 1400px) {
    .settings-home-confirm-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}