.settings-vehicle-list-page{
    align-items: center;
    display: flex;   
    height: 70px;
    margin-top: 25px;
    min-width: 922px;
    max-width: 1074px;
    width: 90%;
}
.settings-vehicle-list-label-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings-vehicle-list-label{
    letter-spacing: 0;
    margin-right: 142px;
    min-width: 160px;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #021E20;
}
.settings-vehicle-list{
    min-width: 293px;
    max-width: 343px;
    width: 91.5%;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}
.settings-vehicle-list-header{
    display: flex;
    height: 29px;
    flex-direction: row;
    justify-content: center;
}
.settings-vehicle-list-header-frame{
    display: flex;
    width: 700px;
    margin-left: -311px;
    height: 29px;
    flex-direction: row;
    align-items: flex-end;
}
.settings-vehicle-list-header-no{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    width: 35px;
    color: #667172;
    margin-left: 40px;
}
.settings-vehicle-list-header-vehicle-name{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    width: 60px;
    text-align: center;
    color: #667172;
    margin-left: 200px;
}
.settings-vehicle-list-header-home{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
    width: 60px;
    margin-left: 300px;
}
.settings-vehicle-list-line{
    border: 1px solid #D9D8D6;
    margin-top: 12px;
}
.settings-vehicle-list-data{
    margin-top: 24px;
    min-width: 1003px;
    max-width: 1170px;
    width: 100%;
    min-height: 330px;
    height: calc(100vh - 310px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.settings-vehicle-list-data-mb{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.settings-vehicle-list-item{
    min-width: 293px;
    max-width: 343px;
    width: 100%;
    min-height: 138px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.settings-vehicle-list-item-content{
    min-width: 293px;
    max-width: 317px;
    min-height: 33px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px;
}
.settings-vehicle-list-item-no-frame{
    width: 48px;
    min-height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.settings-vehicle-list-item-no{
    width: 12px;
    margin-top: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #667172;
}
.settings-vehicle-list-item-vehicle-icon-frame{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 52px;
}
.settings-vehicle-list-item-vehicle-icon{
    width: 48px;
    height: 48px;
}
.settings-vehicle-list-item-vehicle-frame{
    width: 120px;
    min-height: 33px;
    display: flex;
    align-items: center;
    margin-left: 0px;
    overflow-wrap: break-word;
}
.settings-vehicle-list-item-vehicle{
    min-width: 96px;
    max-width: 96px;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    color: #667172;
    margin-top: 4px;
    max-width: 146px;
    word-wrap: break-word;
    line-break: anywhere;
}
.settings-vehicle-list-item-home-icon-frame{
    width: 32px;
    min-width: 32px;
    height: 32px;
}
.settings-vehicle-list-item-home-frame{
    min-width: 110px;
    min-height: 32px;
    max-width: 96px;
    display: flex;
    align-items: center;
    margin-left: 3px;
}
.settings-vehicle-list-item-home{
    min-width: 130px;
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 17px;
    color: #00ADBB;
    word-wrap: break-word;
    line-break: anywhere;
}
.settings-vehicle-list-item-link{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #00ADBB;
    margin-bottom: 27px;
}
@media (max-width: 1023px) {
    .settings-vehicle-list-page{
        display: none;
    }
}
@media (min-width: 1024px){
    .settings-vehicle-list{
        min-width: 1003px;
        max-width: 1170px;
        width: 98%;
        margin-top: 20px;
    }
    .settings-vehicle-list-item{
        min-width: 1003px;
        max-width: 1170px;
        min-height: 92px;
        margin-bottom: 12px;
        margin-top: 0px;
        flex-direction: row;
        justify-content: center;
    }
    .settings-vehicle-list-item-content{
        min-width: 950px;
        max-width: 950px;
        height: 92px;
        margin-top: 0px;
    }
    .settings-vehicle-list-item-link{
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 0px;
    }
    .settings-vehicle-list-item-no{
        width: 34px;
        margin-top: 1px;
        font-size: 24px;
        line-height: 33px;
    }
    .settings-vehicle-list-item-vehicle-frame{
        width: 290px;
        min-height: 92px;
        margin-left: 4px;
    }
    .settings-vehicle-list-item-vehicle{
        min-width: 10px;
        max-width: 280px;
        font-size: 24px;
        line-height: 28px;
        margin-top: 3px;
    }
    .settings-vehicle-list-item-home-icon-frame{
        width: 48px;
        height: 48px;
    }
    .settings-vehicle-list-item-home-frame{
        min-width: 290px;
        max-width: 290px;
        min-height: 92px;
        margin-left: 4px;
    }
    .settings-vehicle-list-item-home{
        min-width: 117px;
        max-width: 280px;
        font-size: 30px;
        line-height: 28px;
    }
}
@media (min-width: 1400px) {
    .settings-vehicle-list-page{
        min-width: 1072px;
        max-width: 1072px;
    }
    .settings-vehicle-list{
        min-width: 1336px;
        max-width: 1336px;
    }
    .settings-vehicle-list-data{
        min-width: 1336px;
        max-width: 1336px;
        height: calc(100vh - 285px);
    }
    .settings-vehicle-list-item{
        min-width: 1336px;
        max-width: 1336px;
    }
}