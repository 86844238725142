.send-select-destination-root{
    font-style: normal;
    font-weight: bold;
}
.send-select-destination-page{
    display: flex;
    align-items: center;
    height: 52px;
    min-width: 198px;
    margin-top: 18px;
}
.send-select-destination-label-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.send-select-destination-label{
    min-width: 198px;
    width: 98%;
}
.send-destination-address{
    letter-spacing: 0;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}
.send-target-vehicle{
    color: #00ADBB;
}
.send-select-destination-root>.destination-list{
    margin-top: 12px;
}
@media (max-width:1023px) {
    .send-select-destination-page > .back {
        display: none;
    }
}
@media (min-width: 1024px){
    .send-select-destination-page{
        height: 70px;
        width: 90%;
        min-width: 922px;
        max-width: 1074px;
        margin-top: 25px;
    }
    .send-select-destination-label{
        min-width: 447px;
    }
    .send-destination-address{
        font-size: 32px;
        line-height: 46px;
    }
    .send-select-destination-root>.destination-list{
        margin-top: 30px;
    }
}
@media (min-width: 1400px) {
    .send-select-destination-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}