.send-confirm-root{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}
.send-confirm-title{
    display: flex;
    align-items: center;
    height: 52px;
    width: 98%;
    min-width: 198px;
    margin-top: 18px;
}
.send-confirm-text-frame{
    width: 100%;
}
.send-confirm-text{
    color:#011d1f;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    min-width: 198px;
}
.send-confirm-text-vehicle{
    color: #00ADBB;
}
.send-confirm-content-frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 91.5%;
    height: 368px;
    min-width: 292px;
    max-width: 343px;
    background-color: white;
    border-radius: 8px;
    margin-top: 24px;
    justify-content: space-evenly;
}
.send-arrow-icon{
    min-width: 18px;
}
@media (max-width:1023px) {
    .send-confirm-title > .back {
        display: none;
    }
    .send-confirm-root .button-frame{
        margin-top: 15px;
    }
}
@media (min-width: 1024px) {
    .send-confirm-title{
        height: 70px;
        width: 90%;
        min-width: 922px;
        max-width: 1074px;
        margin-top: 25px;
    }
    .send-confirm-text{
        font-size: 32px;
        line-height: 46px;
        min-width: 544px;
        margin-left: 10px;
    }
    .send-confirm-content-frame{
        flex-direction: row;
        height: 259px;
        width: 809px;
        min-width: 809px;
        margin-top: 30px;
    }
    .send-arrow-icon{
        min-width: 79px;
        margin-top: 50px;
    }
    .send-confirm-root .button-frame{
        margin-top: 20px;
    }
}
@media (min-width: 1400px) {
    .send-confirm-title{
        min-width: 1072px;
        max-width: 1072px;
    }
}