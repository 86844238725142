.settings-sync-vehicle-page{
    align-items: center;
    display: flex;   
    height: 52px;
    margin-top: 18px;
    min-width: 180px;
    width: 90%;
    position: relative;
}
.settings-sync-vehicle-label-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings-sync-vehicle-label{
    min-width: 160px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #021E20;
}
.settings-sync-vehicle-root .button-frame{
    margin-top: 42px;
}
@media (max-width:1023px) {
.settings-sync-vehicle-page > .back {
        display: none;
    }
}
@media (min-width: 1024px){
    .settings-sync-vehicle-page{
    height: 70px;
    margin-top: 25px;
    min-width: 922px;
    max-width: 1074px;
    }
    .settings-sync-vehicle-label{
    margin-right: 142px;
    min-width: 160px;
    font-size: 32px;
    line-height: 46px;
    }
    .settings-sync-vehicle-root .button-frame{
        margin-top: 200px;
    }
}