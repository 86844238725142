.reservation-cancel-confirm-title{
    align-items: center;
    display: flex;
    margin-top: 18px;
    height: 52px;
    min-width: 256px;
    max-width: 256px;
    width: 90%;
}
.reservation-cancel-confirm-text-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reservation-cancel-confirm-text{
    color:#011d1f;
    min-width: 256px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}
.reservation-cancel-confirm-content-frame{
    align-items: center;
    min-width: 292px;
    max-width: 343px;
    width: 91.5%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    height: 368px;
    margin-top: 24px;
    flex-direction: column;
    justify-content: space-evenly;
}
.reservation-cancel-vehicle-icon{
    height: 48px;
    width: 48px;
}
.reservation-cancel-arrow-icon{
    min-width: 18px;
}   
.reservation-cancel-confirm-root > .reservation-cancel-confirm-content-frame > .enable-target-point > .component-target-point-label-frame{
    min-width: 96px;
}
.reservation-cancel-confirm-root > .button-frame{
    margin-top: 16px;
}
@media (max-width:1023px) {
    .reservation-cancel-confirm-title > .back {
        display: none;
    }
}
@media (min-width: 1024px) {
    .reservation-cancel-confirm-title{
        height: 70px;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
        width: 90%;
    }
    .reservation-cancel-confirm-text{
        margin-right: 142px;
        min-width: 578px;
        font-size: 32px;
        line-height: 46px;
    }
    .reservation-cancel-confirm-content-frame{
        min-width: 912px;
        max-width: 1066px;
        width: 90%;
        border-radius: 8px;
        height: 300px;
        margin-top: 30px;
        flex-direction: row;
    }
    .reservation-cancel-vehicle-icon{
        height: 80px;
        margin-top: 40px;
        width: 80px;
    }
    .reservation-cancel-arrow-icon{
        margin-top: 50px;
        min-width: 79px;
    }
    .reservation-cancel-confirm-root > .reservation-cancel-confirm-content-frame > .enable-target-point > .component-target-point-label-frame{
        min-width: 180px;
    }
    .reservation-cancel-confirm-root > .button-frame{
        margin-top: 20px;
    }
}
@media (min-width: 1400px) {
    .reservation-cancel-confirm-title{
        min-width: 1072px;
        max-width: 1072px;
    }
}