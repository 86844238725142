.vehicle-run-page{
    align-items: center;
    display: flex;   
    height: 52px;
    margin-top: 18px;
    min-width: 180px;
    width: 98%;
    position: relative;
}
.vehicle-run-page-label-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vehicle-run-page-label{
    letter-spacing: 0;
    min-width: 252px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #021E20;
}
.vehicle-run-page-label-vehicle{
    color: #00ADBB;
}
.vehicle-run-confirm-content-frame{
    align-items: center;
    min-width: 292px;
    max-width: 343px;
    width: 91.5%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    height: 300px;
    margin-top: 20px;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
}
.vehicle-run-vehicle-icon{
    height: 64px;
    width: 64px;
}
.vehicle-run-arrow-icon{
    min-width: 18px;
}
.vehicle-run-caution-msg-frame{
    margin-top: 12px;
    border: 3px solid #EB5757;
    box-sizing: border-box;
    border-radius: 7px;
    max-width: 344px;
    min-width: 310px;
    width: 91.5%;
    height: 80px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicle-run-caution-icon{
    height: 48px;
    width: 48px;
}
.vehicle-run-caution-msg{
    display: flex;
    align-items: center;
    min-width: 246px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #EB5757;
    margin-left: 8px;
}
.slider-outside-frame{
    user-select:none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 292px;
    height: 56px;
    background: #F1F9FA;
    box-shadow: 4px 4px 8px #E2E2E2, -4px -4px 8px #FFFFFF, inset 4px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 10px;
}
.slider-inside-frame{
    display: flex;
    flex-direction: row;
    width: 284px;
    height: 48px;
    background: #00ADBB;
    box-shadow: inset 0px -7px 5px rgba(255, 255, 255, 0.37), inset -4px 12px 15px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
}
.slide-button{
    position: relative;
    top:0px;
    left: 0px;
    width: 45px;
    height: 48px;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3), 4px 0px 3px rgba(0, 0, 0, 0.2), -1px -2px 6px rgba(255, 255, 255, 0.65), inset -1px -1px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 4px rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
}
.slide-button-text{
    position: relative;
    top: 9px;
    left: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    user-select:none
}

@media (max-width: 1023px) {
    .vehicle-run-page > .back {
        display: none;
    }
}
@media (min-width:1024px) {
    .vehicle-run-page{
        height: 70px;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
        width: 90%;
    }
    .vehicle-run-page-label{
        margin-left: 10px;
        min-width: 544px;
        font-size: 32px;
        line-height: 46px;
    }
    .vehicle-run-confirm-content-frame{
        min-width: 809px;
        width: 809px;
        height: 259px;
        margin-top: 30px;
        flex-direction: row;
    }
    .vehicle-run-vehicle-icon{
        height: 80px;
        margin-top: 53px;
        width: 80px;
    }
    .vehicle-run-arrow-icon{
        min-width: 79px;
        margin-top: 50px;
    }
    .vehicle-run-caution-msg-frame{
        margin-top: 24px;
        min-width: 598px;
        max-width: 598px;
        width: 100%;
        height: 126px;
        border-radius: 14px;
        border: 4px solid #EB5757;
        flex-direction: row;
    }
    .vehicle-run-caution-icon{
        height: 80px;
        margin-left: 32px;
        width: 80px;
    }
    .vehicle-run-caution-msg{
        font-size: 28px;
        line-height: 41px;
        min-width: 419px;
        height: 82px;
        margin-left: 24px;
    }
    .vehicle-run-confirm-root > .button-frame{
        margin-top: 12px;
        height: 109px;
    }
    .vehicle-run-confirm-root > .button-frame>.button-bg-frame{
        margin-bottom: 1px;
    }
    .slider-outside-frame{
        width: 400px;
        height: 80px;
        box-shadow: 5px 5px 10px #E2E2E2, -5px -5px 10px #FFFFFF, inset 5px 5px 20px rgba(0, 0, 0, 0.05);
        margin-top: 25px;
    }
    .slider-inside-frame{
        width: 388px;
        height: 68px;
        box-shadow: inset 0px -7px 5px rgba(255, 255, 255, 0.37), inset -4px 12px 15px rgba(0, 0, 0, 0.3);
    }
    .slide-button{
        width: 64px;
        height: 68px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3), 10px 0px 5px rgba(0, 0, 0, 0.2), -2px -4px 8px rgba(255, 255, 255, 0.65), inset -2px -2px 4px rgba(0, 0, 0, 0.2), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
    }
    .slide-button-text{
        top:12px;
        font-size: 28px;
        line-height: 41px;
    }
}
@media (min-width: 1400px) {
    .vehicle-run-page{
        min-width: 1072px;
        max-width: 1072px;
    }
}
