.button-frame{
    align-items: center;
    align-self: center;
    justify-content: center;
    display: flex;
    height: 80px;
    width: 312px;
}
.button-bg-frame{
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: center;
    min-width: 292px;
    background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
    box-shadow: 2px 0px 8px #8CAEB0, -2px 0px 8px #BCE4E8, inset -4px -4px 8px rgba(255, 255, 255, 0.24), inset 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.button-text{
    font-weight: 500;
    font-style: normal;
    height: 29px;
    letter-spacing: 0;
    min-height: 29px;
    min-width: 80px;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}
.green-button > .button-bg-frame{
    background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
    box-shadow: 2px 0px 8px #8CAEB0, -2px 0px 8px #BCE4E8, inset -4px -4px 8px rgba(255, 255, 255, 0.24), inset 4px 4px 8px rgba(0, 0, 0, 0.1);
}
.green-button > .button-bg-frame > .button-text{
    color: #FFFFFF;
}
.white-button > .button-bg-frame{
    background: linear-gradient(312.47deg, #F5F5F5 15.15%, #FFFFFF 84.73%);
    box-shadow: 4px 4px 8px #E2E2E2, -4px -4px 8px #FFFFFF, inset 4px 4px 8px rgba(0, 0, 0, 0.05);
}
.white-button > .button-bg-frame > .button-text{
    color: #00ADBB;
}

@media(min-width:1024px) {
   
    .button-frame{
        height: 110px;
        width: 430px;
    }
    .button-bg-frame{
        height: 80px;
        min-width: 400px;
        box-shadow: 5px 5px 10px #97B9BC, -5px -4px 10px #FFFFFF, inset 5px 5px 14px rgba(0, 0, 0, 0.1);
    }
    .button-text{
        height: 48px;
        min-height: 48px;
        font-size: 28px;
        line-height: 41px;
    
    }
    .green-button > .button-bg-frame{
        box-shadow: 5px 5px 10px #97B9BC, -5px -4px 10px #FFFFFF, inset 5px 5px 14px rgba(0, 0, 0, 0.1);
    }
    .white-button > .button-bg-frame{
        box-shadow: 5px 5px 10px #E2E2E2, -5px -5px 10px #FFFFFF, inset 5px 5px 20px rgba(0, 0, 0, 0.05);
    }
}