.simple-msges-frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    width: 91.5%;
    min-height: 162px;
    min-width: 293px;
    max-width: 343px;
    margin-top: 32px;
}
.simple-msges-button-frame{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 316px;
    margin-top: 32px;
}
.simple-msges-frame > .icon{
    height: 48px;
    width: 48px;
    margin-top: 32px;
}
.simple-msges-button-frame > .button-frame > .button-bg-frame > .button-text{
    font-weight: 500;
}
.complete-text{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
    color: #021E20;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    min-width: 270px;
    margin-top: 16px;
    white-space: pre-wrap;
}
@media (min-width: 1024px) {
    .simple-msges-frame {
        width: 100%;
        min-height: 286px;
        min-width: 600px;
        max-width: 600px;
        margin-top: 20px;
    }
    .simple-msges-button-frame{
        margin-top: 12px;
    }
    .simple-msges-frame > .icon{
        margin-top: 70px;
        height: 80px;
        width: 80px;
    }
    .complete-text{
        font-size: 32px;
        line-height: 46px;
        min-width: 384px;
        margin-top: 16px;
    }
}