.password-frame{
    min-height: 71px;
    min-width: 311px;
}
.password-frame > .password-label{
    font-size: 14px;
    line-height: 20px;
    color: #667172;
}
.password-frame > .password-input-frame{
    display: flex;
    align-items: center;
    min-height: 43px;
    min-width: 311px;
    margin-top: 8px;
}
.password-frame > .password-input-frame > input {
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    background-color: white;
    border-radius: 8px;
    border: 2px solid #D9D8D6;
    min-height: 23px;
    min-width: 279px;
    padding: 10px 16px;
}
.password-frame > .password-input-frame > input::-ms-reveal {
    visibility:hidden
}
.password-frame > .password-input-frame > div {
    font-size: 14px;
    line-height: 20px;
    color: #00ADBB;
    z-index: 10;
    min-width: 28px;
    margin-left: -40px;
}

@media (min-width: 1024px) {
    .password-frame{
        min-height: 116px;
        min-width: 560px;
    }
    .password-frame > .password-label{
        font-size: 22px;
        line-height: 32px;
    }
    .password-frame > .password-input-frame{
        min-height: 69px;
        min-width: 560px;
        margin-top: 12px;
    }
    .password-frame > .password-input-frame > input {
        font-size: 32px;
        line-height: 40px;
        min-height: 45px;
        min-width: 516px;
        padding: 12px 22px;
    }
    .password-frame > .password-input-frame > div {
        font-size: 24px;
        line-height: 40px;
        min-width: 48px;
        margin-left: -72px;
    }
}
