/**
 * 発進通知設定
 * * 通知ON/OFF
 * * 通知ボリューム
 */
div.settings-notification-root {
    margin-bottom: 2em;
}

section.settings-notification-for-vehicles {
    width: 80%;
    max-width: 300px;
    font-size: 14px;
    line-height: 20px;
    color: #667172;
}

section.settings-notification-for-vehicles > fieldset {
    border: none;
    margin-bottom: 1em;
}

section.settings-notification-for-vehicles > fieldset > legend {
    display: none;
    font-size: 1.5em;
}

section.settings-notification-for-vehicles > fieldset > div {
    margin-top: 1em;
}

/**
 * 通知設定の各項目
 */
section.settings-notification-for-vehicles > fieldset > div > div.input-range-frame > div {
    margin-top: 1.2em;
    display: flex;
}
section.settings-notification-for-vehicles > fieldset > div > div.input-range-frame > div > div {
    /** スライダーinput-range要素とその前後のテキストの縦方向の位置を調整 */
    margin-top: -0.3em;
}
section.settings-notification-for-vehicles > fieldset > div > div.input-range-frame > div > div:first-child {
    margin-left: 1em;
}
section.settings-notification-for-vehicles > fieldset > div > div.input-range-frame > label {
    font-size: 1.2em;
}

/**
 * 通知ON/OFF
 */
section.settings-notification-for-vehicles > fieldset > div.settings-notification-enabled > div.input-range-frame > div > input {
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

/**
 * 通知ボリューム
 */
section.settings-notification-for-vehicles > fieldset > div.settings-notification-volume > div.input-range-frame > div > input {
    max-width: 200px;
    margin-left: 10px;
    margin-right: 10px;
}
section.settings-notification-for-vehicles > fieldset > div.settings-notification-volume div.volume-control {
    cursor: pointer;
}
section.settings-notification-for-vehicles > fieldset > div.settings-notification-volume div.volume-control img {
    width: 2em;
    height: 2em;
}
section.settings-notification-for-vehicles > fieldset > div.settings-notification-volume > div.input-range-frame > div > div {
    /** スライダーinput-range要素とその前後のミュート、ボリューム最大化の縦方向の位置を調整（上書き） */
    margin-top: -0.5em;
}

@media (min-width: 1024px) {
    section.settings-notification-for-vehicles {
        font-size: 22px;
        line-height: 32px;
    }
    section.settings-notification-for-vehicles > fieldset > div > div.input-range-frame > div > div {
        /** スライダーinput-range要素とその前後のテキストの縦方向の位置を調整 */
        margin-top: -0.5em;
    }
    section.settings-notification-for-vehicles > fieldset > div.settings-notification-volume > div.input-range-frame > div > div {
        /** スライダーinput-range要素とその前後のミュート、ボリューム最大化の縦方向の位置を調整（上書き） */
        margin-top: -0.7em;
    }
}
