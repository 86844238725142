.error-root{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}
.error-frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    min-height: 196px;
    min-width: 293px;
    max-width: 343px;
    width: 91.5%;
    margin-top: 32px;
}
.error-root .button-frame{
    width: 316px;
    height: 80px;
    margin-top: 32px;
}
.error-root .button-text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    min-width: 61px;
}
.error-icon{
    height: 48px;
    width: 48px;
    margin-top: 32px;
}

.error-text{
    color: #021E20;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    min-height: 52px;
    min-width: 269px;
    margin-top: 16px;
}
@media (min-width: 1024px) {
    .error-frame{
        min-height: 286px;
        width: 100%;
        min-width: 600px;
        max-width: 600px;
        margin-top: 145px;
    }
    .error-icon{
        height: 80px;
        width: 80px;
        margin-top: 64px;
    }
    .error-text{
        font-size: 32px;
        line-height: 46px;
        min-height: 92px;
        min-width: 382px;
        margin-top: 16px;
    }
    .error-root .button-frame{
        height: 110px;
        width: 430px;
        margin-top: 64px;
    }
    .error-root .button-text{
        font-weight: 500;
        min-width: 352px;
    }
}