.question-dialog-overray{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10;
}
.question-msg-dialog{
    align-items: center;
    display: flex;
    height: 195px;
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    position: absolute;
    top: 50px;
}
.question-msg-dialog-bg{
    align-items: center;
    background-size: 100% 100%;
    display: flex;
    height: 135px;
    justify-content: center;
    width: 340px;
}
.question-msg-text{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    min-width: 238px;
    text-align: center;
    margin-bottom: 15px;
}
@media (min-width: 1024px){
    .question-msg-dialog{
        top: 235px;
    }
    .question-msg-dialog-bg{
        height: 195px;
        min-width: 510px;
        width: 510px;
    }
    .question-msg-text{
        font-size: 24px;
        line-height: 35px;
        min-width: 409px;
        margin-bottom: 22px;
    }
}