.schedule-list-page{
    min-width: 293px;
    max-width: 343px;
    width: 91.5%;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}
.schedule-list-header{
    display: flex;
    height: 29px;
    justify-content: center;
}
.schedule-list-header-frame{
    display: flex;
    height: 29px;
    align-items: flex-end;
    min-width: 1003px;
    max-width: 1108px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.schedule-list-header-no-frame{
    width: 48px;
    min-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.schedule-list-header-no{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    width: 35px;
    color: #667172;
}
.schedule-list-header-vehicle-name-frame{
    width: 180px;
    min-height: 29px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
.schedule-list-header-vehicle-name{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    width: 60px;
    text-align: center;
    color: #667172;
}
.schedule-list-header-call-point-frame{
    width: 220px;
    min-height: 29px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
.schedule-list-header-call-point{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    width: 120px;
    text-align: center;
    color: #667172;
}
.schedule-list-header-destination-point-frame{
    width: 220px;
    min-height: 29px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
.schedule-list-header-destination-point{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
    width: 60px;
}
.schedule-list-header-status-frame{
    width: 220px;
    min-height: 29px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
}
.schedule-list-header-status{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
}
.schedule-list-header-register-date-frame{
    min-width: 160px;
    max-width: 160px;
    min-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.schedule-list-header-register-date{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
    width: 80px;
}
.schedule-list-line{
    border: 1px solid #D9D8D6;
    margin-top: 12px;
}
.schedule-list{
    margin-top: 24px;
    min-width: 1003px;
    max-width: 1170px;
    width: 100%;
    min-height: 420px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.schedule-list-mb{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.schedule-list-item{
    min-width: 293px;
    max-width: 343px;
    width: 100%;
    min-height: 180px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.schedule-list-item-info-frame{
    min-width: 293px;
    max-width: 311px;
    width: 100%;
    min-height: 27px;
    display: flex;
    margin-top: 16px;
}
.schedule-list-item-navi-frame{
    width: 100%;
    min-height: 32px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    word-wrap: break-word;
    line-break: anywhere;
}
.schedule-list-item-navi-frame-2{
    min-width: 293px;
    max-width: 311px;
    min-height: 32px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    flex-direction: column;
    align-items: flex-start;
    word-wrap: break-word;
    line-break: anywhere;
}
.schedule-list-item-frame{
    min-width: 1003px;
    max-width: 1108px;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.schedule-list-item-no-frame{
    width: 48px;
    min-height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.schedule-list-item-no{
    width: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #667172;
}
.schedule-list-item-vehicle-name-frame{
    width: 145px;
    min-height: 23px;
    display: flex;
    align-items: center;
    margin-left: 25px;
    /*overflow-wrap: break-word;*/
    word-wrap: break-word;
    line-break: anywhere;
}
.schedule-list-item-vehicle-name{
    min-width: 96px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #667172;
}
.schedule-list-item-call-point-frame{
    width: 220px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
.schedule-list-item-call-point{
    font-style: normal;
    font-weight: bold;
    color: #00ADBB;
    min-width: 150px;
    word-wrap: break-word;
    line-break: anywhere;
}
.schedule-list-item-destination-point-frame{
    width: 220px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
.schedule-list-item-destination-point-frame-2{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.schedule-list-item-destination-point{
    font-style: normal;
    font-weight: bold;
    color: #00ADBB;
    min-width: 150px;
    word-wrap: break-word;
    line-break: anywhere;
}
.schedule-list-item-status-frame{
    width: 220px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
}
.schedule-list-item-status{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #667172;
    min-width: 184px;
}
.schedule-list-item-register-date-frame{
    min-width: 92px;
    max-width: 135px;
    min-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.schedule-list-item-register-date-icon{
    width: 16px;
    height: 16px;
    margin-right: 2px;
}
.schedule-list-item-register-date-dt{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #667172;
    min-width: 92px;
}
.schedule-list-item-register-date-time{
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30.78px;
    text-align: center;
    color: #667172;
    min-width: 82px;
}
.schedule-list-item-status-button-frame{
    background: #D9D8D6;
    border-radius: 4px;
    width: 166px;
    height: 39px;
    margin-top: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
}
.schedule-list-item-arrow-frame{
    width: 32px;
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 5px;
}
.schedule-list-item-arrow-icon{
    width: 32px;
    height: 18px;
}
.schedule-list-vehicle-run-button{
    margin-top:24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 49px;
    background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
    box-shadow: 2px 0px 8px #8CAEB0, -2px 0px 8px #BCE4E8, inset -4px -4px 8px rgba(255, 255, 255, 0.24), inset 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.schedule-list-run-button-text{
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF !important; 
}
@media (min-width: 1024px){
    .schedule-list-page{
        min-width: 1003px;
        max-width: 1170px;
        width: 98%;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
    }
    .schedule-list-item{
        min-width: 1003px;
        max-width: 1170px;
        min-height: 92px;
        border-radius: 8px;
        margin-top: 0px;
        margin-bottom: 12px;
        justify-content: center;
        flex-direction: row;
    }
    .schedule-list-item-no{
        width: 14px;
        margin-top: 5px;
        font-size: 24px;
        line-height: 33px;
    }
    .schedule-list-item-vehicle-name-frame{
        width: 180px;
        min-height: 92px;
        margin-left: 15px;
        justify-content: center;
    }
    .schedule-list-item-vehicle-name{
        margin-top: 5px;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
    }
    .schedule-list-item-register-date-frame{
        min-width: 160px;
        max-width: 160px;
        min-height: 92px;
        flex-direction: column;
    }
    .schedule-list-item-register-date-dt{
        font-size: 18px;
        line-height: 18.47px;
        min-width: 82px;
    }
    .schedule-list-item-call-point{
        font-size: 30px;
        line-height: 28px;
        text-align: center;
        min-width: 117px;
    }
    .schedule-list-item-destination-point{
        font-size: 30px;
        line-height: 28px;
        text-align: center;
        min-width: 117px;
    }
    .schedule-list-item-status{
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        color: #667172;
        min-width: 184px;
    }
    .schedule-list-vehicle-run-button{
        margin-top: 0px;
        margin-bottom: 0px;
        width: 190px;
        height: 60px;
        background: linear-gradient(312.47deg, #00ADBB 15.15%, #40C6D9 84.73%);
        box-shadow: 5px 5px 10px #97B9BC, -5px -4px 10px #FFFFFF, inset 5px 5px 14px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }
    .schedule-list-run-button-text{
        font-size: 23px;
        line-height: 33px;
    }
}
@media (min-width: 1400px) {
    .schedule-list-page{
        min-width: 1336px;
        max-width: 1336px;
    }
    .schedule-list{
        min-width: 1336px;
        max-width: 1336px;
        height: calc(100vh - 195px);
    }
    .schedule-list-item{
        min-width: 1336px;
        max-width: 1336px;
    }
}