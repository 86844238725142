.view > div.input-frame{
    margin-top: 56px;
}
.view > div.input-frame + div.input-frame{
    margin-top: 24px;
}

::placeholder {
    color: #D9D8D6;
    font-size: 16px;
    font-weight: normal;
}
.view .button-frame{
    margin-top: 40px;
    margin-right: -1px;
}
.logo{
    align-self: center;
    object-fit: cover;
    height: 52px;
    width: 225px;
}
.view{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: #f1f9fa;
    padding-top: 56px;
}
.view > .input-frame > #password::-ms-reveal{
    visibility:hidden
}

@media (min-width: 1400px) {
    .view{
        padding-top: 120px;
    }
}
@media(min-width:1024px){
    ::placeholder {
        color: #D9D8D6;
        font-size: 32px;
        font-weight: 400;
    }
    .view > div.input-frame + div.input-frame{
        margin-top: 48px;
    }
    .view > .input-frame > div{
        font-size: 24px;
        line-height: 40px;
    }
    .view > .input-frame > input{
        font-size: 32px;
        font-weight: 400;
        height: 45px;
        min-height: 40px;
        min-width: 516px;
        padding: 12px 22px;
        margin-top: 12px;
    }

    .logo{
        height: 96px;
        width: 415px;
        margin-left: 1px;
    }
    .view{
        padding-top: 112px;
    }
    .view .button-frame{
        margin-top: 64px;
        margin-right: 2px;
    }
}

.valign-text-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
}