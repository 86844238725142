.destination-list{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.destination-list-1{
    min-width: 311px;
}
.destination-list-item{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 80px;
}
.destination-list-item-bg-frame{
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: center;
    min-width: 292px;
    background: linear-gradient(312.47deg, #F5F5F5 15.15%, #FFFFFF 84.73%);
    box-shadow: 5px 5px 10px #E2E2E2, -5px -5px 10px #FFFFFF, inset 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}
.destination-list-item-text{
    min-height: 20px;
    letter-spacing: 0;
    min-width: 252px;
    font-weight: bold;
    width: 252px;
    color: #00ADBB;
    font-size: 20px;
    line-height: 17px;
    word-wrap: break-word;
    line-break: anywhere;
    margin-left: 30px;
    margin-right: 10px;
}
.destination-list-2{
    min-width: 311px;
}
.destination-list-3{
    min-width: 311px;
}
@media (min-width: 1024px) {
    .destination-list-1{
        flex-wrap: wrap;
        align-content: space-around;
        min-width: 996px;
        height: 440px;
    }
    .destination-list-item{
        height: 110px;
    }
    .destination-list-item-bg-frame{
        height: 80px;
        min-width: 400px;
    }
    .destination-list-item-text{
        min-height: 27px;
        width: 360px;
        font-size: 28px;
        line-height: 23px;
    }
    .destination-list-2{
        flex-wrap: wrap;
        align-content: space-around;
        min-width: 996px;
        height: 440px;
    }
    .destination-list-3{
        max-width: 1091.63px;
        min-width: 1024px;
        width: 100%;
        height: 460px;
        flex-wrap: wrap;
        align-content: space-between;
        overflow-x: auto;
    }
    .destination-list-3> .destination-list-item{
        margin-right: 5px;    
        margin-left: 5px; 
    }
    .destination-list-3> .destination-list-item>.destination-list-item-bg-frame{
        min-width: 311.63px;
    }
    .destination-list-3> .destination-list-item>.destination-list-item-bg-frame > .destination-list-item-text{
        min-width: 261.63px;
        width: 261.63px;
    }
}