.header{
    align-items: center;
    background-color: #00ADBB;
    display: flex;
    min-width: 320px;
    width: 100%;
    flex-direction: column;
}
.use-point-frame{
    height: 77px;
    display: flex;
    align-items: center;
    min-width: 320px;
    width: 100%;
}
.menu-icon{
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}
.menu-frame{
    height: 53px;
    position: relative;
    min-width: 320px;
    width: 100%;
}
.nav-item-frame{
    align-items: flex-start;
    display: flex;
    height: 53px;
    left: 0;
    min-width: 320px;
    width: 100%;
    position: absolute;
    top: 0;
}
.nav-item-0{
    align-items: center;
    background-color: #00ADBB;
    display: flex;
    height: 53px;
    justify-content: center;
    min-width: 51.25px;
}
.nav-item-label-0{
    height: 16px;
    letter-spacing: 0;
    min-height: 16px;
    min-width: 33px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2.5px;
}
.nav-item-1{
    align-items: center;
    background-color: #00ADBB;
    display: flex;
    height: 53px;
    justify-content: center;
    margin-left: 1px;
    min-width: 82.25px;
}
.nav-item-label-1{
    height: 16px;
    letter-spacing: 0;
    min-height: 16px;
    min-width: 64px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2.5px;
}
.nav-item-2{
    align-items: center;
    background-color: #00ADBB;
    display: flex;
    height: 53px;
    justify-content: center;
    margin-left: 1px;
    min-width: 113.25px;
}
.nav-item-label-2{
    height: 16px;
    letter-spacing: 0;
    min-height: 16px;
    min-width: 95px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2.5px;
}
.nav-item-3{
    align-items: center;;
    background-color: #00ADBB;
    display: flex;
    height: 53px;
    justify-content: center;
    min-width: 50.25px;
}
.nav-item-label-3{
    height: 16px;
    letter-spacing: 0;
    min-height: 16px;
    min-width: 32px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2.5px;
}
.nav-item-selected{
    background: #00ADBB;
    box-shadow: inset 0px -6px 0px #008A96;
}
.point-button-frame{
    align-items: flex-start;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    display: flex;
    height: 49px;
    margin-bottom: 1.0px;
    min-width: 166px;
    width: 166px;
    margin-left: 16px;
}
.point-button{
    height: 30px;
    display: flex;
    align-items: flex-end;
    align-self: center;
    padding: 9px 4px 9px ;
}
.link-pos{
    font-weight: bold;
    height: 29px;
    letter-spacing: 0;
    text-align: center;
    font-size: 20px;
    line-height: 17px;
    word-wrap: break-word;
    line-break: anywhere;
    color: #00ADBB;
    width: 101px;
}
.link-logout{
    color: white;
    font-weight: 400;
    height: 80px;
    letter-spacing: 0;
    min-height: 80px;
    min-width: 176px;
    font-size: 16px;
    text-align: center;
}
.pos-icon{
    align-self: center;
    height: 28px;
    width: 28px;
}
.iconsmallarrowdown{
    justify-content: center;
    height: 29px;
    width: 29px;
}
@media (min-width: 375px) {
    .nav-item-0{
        min-width: 65px;
    }
    .nav-item-1{
        min-width: 96px;
    }
    .nav-item-2{
        min-width: 127px;
    }
    .nav-item-3{
        min-width: 64px;
    }
}
@media (min-width: 1024px) {
    .header{
        align-items: center;
        background-color: #00ADBB;
        display: flex;
        min-width: 1000px;
        width: 100%;
        flex-direction: row;
    }
    .menu-frame{
        height: 80px;
        position: relative;
        width: 595px;
        min-width: none;
    }
    .nav-item-frame{
        align-items: flex-start;
        display: flex;
        height: 80px;
        left: 0;
        min-width: 595px;
        position: absolute;
        top: 0;
    }
    .nav-item-0{
        align-items: center;
        background-color: #00ADBB;
        display: flex;
        height: 80px;
        justify-content: center;
        min-width: 113px;
    }
    .nav-item-label-0{
        height: 24px;
        letter-spacing: 0;
        min-height: 24px;
        min-width: 51px;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .nav-item-1{
        align-items: center;
        background-color: #00ADBB;
        display: flex;
        height: 80px;
        justify-content: center;
        margin-left: 1px;
        min-width: 160px;
    }
    .nav-item-label-1{
        height: 24px;
        letter-spacing: 0;
        min-height: 24px;
        min-width: 98px;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .nav-item-2{
        align-items: center;
        background-color: #00ADBB;
        display: flex;
        height: 80px;
        justify-content: center;
        margin-left: 1px;
        min-width: 207px;
    }
    .nav-item-label-2{
        height: 24px;
        letter-spacing: 0;
        min-height: 24px;
        min-width: 145px;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .nav-item-3{
        align-items: center;;
        background-color: #00ADBB;
        display: flex;
        height: 80px;
        justify-content: center;;
        margin-left: 1px;
        min-width: 112px;
    }
    .nav-item-label-3{
        height: 24px;
        letter-spacing: 0;
        min-height: 24px;
        min-width: 50px;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .nav-item-selected{
        background-color: #008a96;
        box-shadow:none
    }
    .point-button-frame{
        align-items: flex-start;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        display: flex;
        height: 49px;
        margin-bottom: 1.0px;
        min-width: 166px;
        width: 166px;
        margin-left: 0px;
    }
}