.reservation-list-page{
    min-width: 343px;
    max-width: 343px;
    margin-top: 4px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 98%;
}
.reservation-list-header{
    display: flex;
    height: 29px;
    justify-content: center;
}
.reservation-list-header-frame{
    display: flex;
    width: 700px;
    margin-left: -246px;
    height: 29px;
    flex-direction: row;
    align-items: flex-end;
}
.reservation-list-header-no{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    width: 35px;
    color: #667172;
}
.reservation-list-header-call-point{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    width: 120px;
    text-align: center;
    color: #667172;
    margin-left: 110px;
}
.reservation-list-header-destination-point{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
    width: 60px;
    margin-left: 335px;
}
.reservation-list-line{
    border: 1px solid #D9D8D6;
    margin-top: 12px;
}
.reservation-list{
    margin-top: 24px;
    min-width: 1003px;
    max-width: 1170px;
    width: 100%;
    min-height: 420px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
.reservation-list-mb{
    min-width: 343px;
    max-width: 343px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.reservation-list-item{
    min-width: 1003px;
    max-width: 1170px;
    width: 100%;
    min-height: 92px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reservation-list-item-mb{
    min-width: 343px;
    max-width: 343px;
    width: 100%;
    min-height: 64px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mb-cancel-enabled{
    min-height: 137px;
}
.reservation-list-item-frame-mb{
    width: 311px;
    min-height: 32px;
    display: flex;
}
.reservation-cancel-frame-mb{
    width: 166px;
    height: 49px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reservation-cancel-text-mb{
    min-width: 90px;
    height: 26px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    text-decoration-line: underline;
    color: #EB5757;
}
.reservation-list-item-frame{
    width: 972px;
    display: flex;
    flex-direction: row;
    min-height: 92px;
}
.reservation-list-item-no-frame{
    width: 48px;
    min-height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reservation-list-point-frame-mb{
    min-width: 224px;
    min-height: 32px;
    display: flex;
    align-items: center;
    margin-left: 25px;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
}
.reservation-list-point-frame-mb-2{
    min-width: 224px;
    min-height: 32px;
    display: flex;
    margin-left: 25px;
    font-size: 18px;
    line-height: 20px;
    flex-direction: column;
}
.reservation-list-destination-point-frame-mb-2{
    min-width: 224px;
    min-height: 32px;
    display: flex;
    align-items: center;
}
.reservation-list-item-no{
    width: 12px;
    margin-top: 3px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #667172;
}
.reservation-list-item-call-point-frame{
    width: 180px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 80px;
    justify-content: center;
}
.reservation-list-item-call-point{
    font-style: normal;
    font-weight: bold;
    color: #00ADBB;
    min-width: 120px;
    word-wrap: break-word;
    line-break: anywhere;
}
.reservation-list-item-arrow-frame{
    width: 32px;
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 5px;
}
.reservation-list-item-destination-point{
    font-style: normal;
    font-weight: bold;
    color: #00ADBB;
    min-width: 120px;
    word-wrap: break-word;
    line-break: anywhere;
}
.reservation-list-item-destination-point-frame{
    width: 180px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    justify-content: center;
}
.reservation-list-item-cancel-link-frame{
    width: 180px;
    min-height: 92px;
    display: flex;
    align-items: center;
    margin-left: 64px;
    justify-content: center;
}
.reservation-list-item-cancel-link-disable{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #667172;
    width: 100px;
}
.reservation-list-item-cancel-link-enable{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    text-decoration-line: underline;
    color: #EB5757;
    width: 100px;
}
.reservation-list-item-arrow{
    align-items: center;
    display: flex;
    width: 32px;
    height: 18px;
}
@media (min-width: 1024px)  {
    .reservation-list-page{
        min-width: 1003px;
        max-width: 1170px;
        margin-top: 25px;
        width: 98%;
    }
    .reservation-list-item-no{
        width: 34px;
        margin-top: 0px;
        margin-bottom: 1px;
        font-size: 24px;
        line-height: 33px;
    }
    .reservation-list-item-call-point{
        font-size: 30px;
        line-height: 28px;
        min-width: 300px;
        text-align: center;
    }
    .reservation-list-item-destination-point{
        font-size: 30px;
        line-height: 28px;
        min-width: 300px;
        text-align: center;
    }
    .reservation-list-item-arrow-frame{
        width: 180px;
        min-height: 92px;
        margin-left: 30px;
        margin-top: 0px;
        margin-right: 0px;
    }
    .reservation-list-item-arrow{
        width: 69px;
        height: 27px;
    }
}
@media (min-width: 1400px) {
    .reservation-list-page{
        min-width: 1336px;
    }
    .reservation-list{
        min-width: 1336px;
        max-width: 1336px;
        height: calc(100vh - 195px);
    }
    .reservation-list-item{
        min-width: 1336px;
        max-width: 1336px;
    }
}