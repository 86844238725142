/**
 * CSSの参照元: https://code-kitchen.dev/html/input-range/
 */
input[type="range"] {
    -webkit-appearance: none; /* これ無しだとスタイルがほぼ全く反映されないので注意 */
    appearance: none;
    cursor: pointer; /* カーソルを分かりやすく */
    outline: none; /* スライダーのアウトラインは見た目がキツイので消す */
    height: 5px; /* バーの高さ */
    width: 100%; /* バーの幅 */
    background: #667172; /*  バーの背景色 */
    border-radius: 10px; /* バーの両端の丸み */
    border: solid 3px #D9D8D6; /* バー周囲の線 */
}
/* WebKit向けのつまみ */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /*  デフォルトのつまみのスタイルを解除 */
    background: #667172; /* #00ADBB 背景色 */
    width: 24px; /* 幅 */
    height: 24px; /* 高さ */
    border-radius: 50%; /* 円形に */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15); /* 影 */
}
/* Moz向けのつまみ */
input[type="range"]::-moz-range-thumb {
    background: #667172; /* #00ADBB 背景色 */
    width: 24px; /* 幅 */
    height: 24px; /* 高さ */
    border-radius: 50%; /* 円形に */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15); /* 影 */
    border: none; /* デフォルトの線を消す */
}
/* Firefoxで点線が周りに表示されてしまう問題の解消 */
input[type="range"]::-moz-focus-outer {
    border: 0;
}
/* つまみをドラッグしているときのスタイル */
input[type="range"]:active::-webkit-slider-thumb {
    box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
}
