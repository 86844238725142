.overray{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.3);
    z-index:10;
}
.car-stop-list{
    align-items: center;
    display: flex;
    height: 58px;
    width: 263px;
    margin-top: 16px;
    flex: 0 0 auto;
}
.car-stop-item-frame{
    align-items: flex-start;
    background-color: white;
    border-radius: 8px;
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.11);
    display: flex;
    min-width: 263px;
}
.address-frame{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 58px;
    width: 263px;
}
.address{
    height: 35px;
    letter-spacing: 0;
    min-height: 35px;
    min-width: 88px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 17px;
    word-wrap: break-word;
    line-break: anywhere;
    color: #00ADBB;
}
.dialog{
    align-items: center;
    background-color: #f1f9f9;
    border-radius: 8px;
    box-shadow: 0px 14px 23px #00000033;
    display: flex;
    flex-direction: column;
    height: 410px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 73px;
    right: 16px;
    width: 288px;
}
.dialog-text{
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    height: 26px;
    letter-spacing: 0;
    margin-top: 30px;
    margin-bottom: 15px;
    min-height: 26px;
    min-width: 216px;
    text-align: center;
}
@media (min-width: 375px) {
    .dialog{
        width: 343px;
    }
}
@media (min-width: 1024px) {
    .car-stop-list{
        height: 70px;
        padding: 0 40px;
        width: 345px;
        margin-top: 20px;
    }
    .car-stop-item-frame{
        min-width: 345px;
    }
    .address-frame{
        height: 70px;
        width: 345px;
    }
    .address{
        height: 43px;
        min-height: 43px;
        min-width: 111px;
        font-size: 25px;
        line-height: 21px;
    }
    .dialog{
        height: 525px;
        top: 80px;
        right: 174px;
        width: 425px;
    }
    .dialog-text{
        font-size: 23px;
        height: 35px;
        margin-top: 35px;
        margin-bottom: 13px;
        min-height: 35px;
        min-width: 289px;
    }
}