.input-frame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 71px;
    min-width: 311px;
}
.input-frame > div{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 20px;
    color: #667172;
    min-height: 20px;
}
.input-frame > input{
    font-weight: normal;
    letter-spacing: 0;
    font-size: 16px;
    background-color: white;
    border-radius: 8px;
    border: 2px solid #D9D8D6;
    height: 23px;
    min-height: 23px;
    min-width: 275px;
    padding: 10px 16px;
    margin-top: 8px;
}