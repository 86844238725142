.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification-detail-loading {
    margin-top: 2em;
    width: 3em;
}

section.notification-detail-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: auto;
    overflow-x: hidden;

    margin-top: 10%;
    width: 80%;
    height: 80%;

    background-color: #f1f9f9;
    border-radius: 8px;
    box-shadow: 0 14px 23px #00000033;
}

section.notification-detail-dialog legend.dialog-title {
    margin-top: 30px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0;
}

section.notification-detail-dialog > p {
    font-size: 18px;
    margin: 20px 25px 0 25px
}

section.notification-detail-dialog ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    width: 80%;
    margin: 0 0 2em 0;
    padding: 0;
}

section.notification-detail-dialog ul > li {
    list-style-type: none;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    font-size: 0.8em;
    margin: 0.5em 0;
    width: 100%;
}

section.notification-detail-dialog ul > li > div {
    min-height: 5em;
}

section.notification-detail-dialog ul > li.departure > .item-frame {
    background-color: #F0F0F0;
}

section.notification-detail-dialog ul > li .icon {
    font-size: 1.5em;
    font-weight: bold;
    margin-right: 0.5em;
    margin-left: 0.2em;
    padding: 0.2em;
    color: #667172;
    word-break: keep-all;
}

section.notification-detail-dialog ul > li > .item-frame {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.11);
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.8em;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule {
    text-align: left;
    font-weight: bold;
    word-wrap: break-word;
    line-break: anywhere;
    color: #667172;
    width: 100%;
    margin-left: 0.5em;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule > p {
    margin: 0.1em;
    font-size: 0.9em;
    min-height: 1.5em;
    line-height: 1.8em;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule > p.time {
    font-size: 0.8em;
    text-align: right;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule span {
    display: inline;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule > p.time > span.schedule-content {
    color: black;
}

section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule span.schedule-content {
    color: #00ADBB;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.1em;
}

@media (max-width: 400px) {
    section.notification-detail-dialog ul > li .icon {
        font-size: 1.2em;
    }
}

@media (min-width: 1024px) {
    section.notification-detail-dialog {
        margin-top: 5%;
        width: 80%;
        max-width: 800px;
    }

    section.notification-detail-dialog > p {
        font-size: 23px;
        margin: 25px 25px 0 25px;
    }
    section.notification-detail-dialog legend.dialog-title {
        font-size: 23px;
        margin-top: 35px;
        margin-bottom: 13px;
    }

    section.notification-detail-dialog ul > li > .item-frame {
        padding-left: 20px;
        padding-right: 20px;
    }

    section.notification-detail-dialog ul > li > .item-frame > .schedule-frame > .schedule {
        font-size: 1.5em;
    }
}
