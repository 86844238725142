.vehicle-pulldown{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    border: 1px solid #D9D8D6;
    box-sizing: border-box;
    border-radius: 8px;
    height: 80px;
    margin-top: 52px;
    min-width: 400px;
}
.vehicle-pulldown-text{
    margin-left: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: #00ADBB;
}
.vehicle-pulldown-icon{
    height: 30px;
    width: 30px;
    margin-right: 24px;
}

.vehicle-pulldown-mb{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    color: #00ADBB;
    border: 1px solid #D9D8D6;
    border-radius: 8px;
    box-sizing: border-box;
    height: 48px;
    min-width: 312px;
    margin-top: 24px;
    padding: 0px 36px 0px 20px;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	background-position: right 12px center;
}