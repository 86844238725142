.reservation-cancel-complete-registed-schedule-root > .simple-msges-frame > .button-frame{
    margin-top: 24px;
    margin-bottom: 10px;
}
@media (min-width: 1024px) {
    .reservation-cancel-complete-registed-schedule-root > .simple-msges-frame{
        min-height: 390px;
    }
    .reservation-cancel-complete-registed-schedule-root > .simple-msges-button-frame{
        margin-top: 32px;
    }
}
