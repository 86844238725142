.return-confirm-title{
    align-items: center;
    display: flex;
    height: 52px;
    margin-top: 18px;
    min-width: 180px;
    width: 98%;
    position: relative;
}
.return-confirm-text-frame{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.return-confirm-text{
    color:#011d1f;
    min-width: 180px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}
.return-confirm-text-vehicle{
    color: #00ADBB;
}
.return-confirm-content-frame{
    align-items: center;
    min-width: 292px;
    max-width: 343px;
    width: 91.5%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    height: 368px;
    margin-top: 24px;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
}
.return-confirm-root > .button-frame{
    margin-top: 16px;
}
.return-arrow-icon{
    margin-right: 1px;
    min-width: 18px;
}
@media (max-width:1023px) {
    .return-confirm-title > .back {
            display: none;
        }
    }
@media (min-width: 1024px) {
    .return-confirm-title{
        height: 70px;
        margin-top: 25px;
        min-width: 922px;
        max-width: 1074px;
    }
    .return-confirm-text{
        margin-left: 10px;
        min-width: 544px;
        font-size: 32px;
        line-height: 46px;
    }
    .return-confirm-content-frame{
        min-width: 809px;
        height: 259px;
        width: 809px;
        margin-top: 30px;
        flex-direction: row;
    }
    .return-arrow-icon{
        margin-top: 50px;
        min-width: 79px;
    }
    .return-confirm-root > .button-frame{
        margin-top: 20px;
    }
}
@media (min-width: 1400px) {
    .return-confirm-title{
        min-width: 1072px;
        max-width: 1072px;
    }
}
