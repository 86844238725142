.pulldown-list-overray{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10;
}
.vehicle-pulldown-list{
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    background-color: white;
    border: 1px solid #D9D8D6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    margin: auto;
    height: 350px;
    position: absolute;
    top: 244px;
    width:451px;
    overflow-y: auto;
    overflow-x: hidden;
}
.vehicle-pulldown-selected-item-bg{
    align-items: flex-start;
    border-radius: 4px;
    display: flex;
    min-height: 79px;
    min-width: 417px;
    background: #F1F9FA;
}
.vehicle-pulldown-selected-item{
    align-items: center;
    display: flex;
    min-height: 78px;
}
.vehicle-pulldown-selected-item-icon{
    margin-left: 16px;
    margin-right: 8px;
}
.vehicle-pulldown-selected-item-text{
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: #00ADBB;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vehicle-pulldown-item{
    align-items: center;
    display: flex;
    min-height: 79px;
    min-width: 417px;
}
.vehicle-pulldown-item-text{
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    color: #667172;
    margin-top: 1px;
    margin-left: 69px;
}