.return-complete-root{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    color: #021E20;
}
.return-complete-root .run-view-text{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
}
.return-complete-root .simple-msges-frame{
    min-height: 196px;
}
.return-complete-root > .simple-msges-frame .button-frame{
    margin-top: 16px;
    margin-bottom: 16px;
}
@media (min-width: 1024px) {
    .return-complete-root .simple-msges-frame{
        min-height: 332px;
    }
    .return-complete-root .simple-msges-frame > .icon{
        margin-top: 32px;
    }
    .return-complete-root .run-view-text{
        font-size: 22px;
        line-height: 32px;
        margin-top: 24px;
    }
    .return-complete-root > .simple-msges-frame .button-frame{
        margin-top: 12px;
    }
}